export const PRODUCTION = 'production';
export const TESTING = 'testing';
export const DEVELOPMENT = 'development';

const validEnvironments = [DEVELOPMENT, TESTING, PRODUCTION];

export const ENVIRONMENT = PRODUCTION; //process.env.NODE_ENV;
export const IS_NORMING_VARIANT = true;

export const IS_IN_PRODUCTION = ENVIRONMENT === 'production';

if (!validEnvironments.includes(ENVIRONMENT)) {
	console.warn('MISSING ENVIRONMENT SELECTOR');
}
const NORMING_ENDPOINTS = {
	development: 'http://localhost:3000',
	testing: 'https://normering.literate.no',
	production: 'https://normering.literate.no',
};

const TEST_ENDPOINTS = {
	development: 'http://localhost:3000',
	testing: 'http://lit-testing.eu-central-1.elasticbeanstalk.com',
	production: 'https://screeningtest.literate.no',
};

const getFollowupTestUrl = () => {
	const languages = {
		no: {
			development: 'http://localhost:3003',
			testing:
				'http://dysmate-followup.literate.no.s3-website.eu-central-1.amazonaws.com',
			production: 'http://barnetest.literate.no',
		},
		se: {
			development: 'http://localhost:3003',
			testing:
				'http://dysmate-followup.dysmate.se.s3-website.eu-central-1.amazonaws.com',
			production: 'http://dysmate-followup.literate.se',
		},
		de: {
			development: 'http://localhost:3003',
			testing:
				'http://dysmate-followup.dysmate.de.s3-website.eu-central-1.amazonaws.com',
			production: 'http://dysmate-followup.literate.de',
		},
	};

	return languages.no[ENVIRONMENT];
};
export const CHILD_TEST_URL = getFollowupTestUrl();
export const SOCKET_ENDPOINT =
	/* IS_NORMING_VARIANT
	? NORMING_ENDPOINTS[ENVIRONMENT]
	:  */ TEST_ENDPOINTS[
		ENVIRONMENT
	];

export const ENDPOINT_URL =
	/* IS_NORMING_VARIANT
	? NORMING_ENDPOINTS[ENVIRONMENT] 
	: */ TEST_ENDPOINTS[
		ENVIRONMENT
	];

export const TYPES = {
	CHILD: 'child',
	ADULT_SCREENING: 'adult-screening',
	ADULT_FOLLOWUP: 'adult-followup',
	YOUTH_SCREENING: 'youth-screening',
	YOUTH_FOLLOWUP: 'youth-followup',
};

export const BANK_ID = 'BankID';
export const BANK_ID_MOBILE = 'BankIDMobile';
