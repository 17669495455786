import React from 'react';

import {getPhonemeDeletion} from 'constants/tests.js';

import AdminTestPanel from 'components/AdminTestPanel';
import strings from 'strings';
import AdminReadyButton from 'components/AdminReadyButton';

class PhonemeDeletionTestAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			stopOnConsecutiveErrors: true,
				maxConsecutiveErrors: 5,
			enableTimers: false,
		});
	}

	getTestName = () => {
		return strings.pd_testname;
	};

	answerWrong = () => {
		const {consecutiveErrors, currentStep, options} = this.state;
		const newResults = Object.assign({}, this.state.results, {
			[currentStep]: {
				isCorrect: false,
				isAnswered: true,
			},
		});
		this.setState(
			{
				wrongAnswers: this.state.wrongAnswers + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = options.maxConsecutiveErrors;
				if (
					currErrors >= maxErrors &&
					options.stopOnConsecutiveErrors
				) {
					if (options.enableTimers) this.props.endTimer();
					this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					if (options.enableTimers) this.props.endTimer();
					this.props.nextStep(this.state.results);
				}
			}
		);
		if (this.answerCB) this.answerCB();
	};

	renderIntro = () => {
		const {currentStep} = this.state;
		const introSteps = getPhonemeDeletion().getIntroSteps();
		const isPracticeTask =
			currentStep > 0 && currentStep < introSteps.length - 1;
		return (
			<>
				<p>{strings.pd_admin1}</p>
				{currentStep === 0 && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={this.props.nextStep}
					/>
				)}
				{isPracticeTask && (
					<>
						{strings.formatString(
							strings.taskNum,
							introSteps[currentStep].task
						)}
						<br />
						{strings.formatString(
							strings.expectedAnswer,
							<b>{introSteps[currentStep].expectedAnswer}</b>
						)}
						<br />
						<button onClick={this.props.nextStep}>
							{strings.correctAnswerGiven}
						</button>
					</>
				)}
				{currentStep === 7 && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={this.props.nextStage}
					/>
				)}
			</>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		return (
			<div>
				<p>
					{strings.pd_admin2}
					<br />
					{strings.hotkeyDescription}
					<br />
					{strings.hotkeyCorrect} <br /> {strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						getPhonemeDeletion().getAnswers().length
					)}
				</p>
				<p>
					{strings.formatString(
						strings.taskNum,
						getPhonemeDeletion().getSteps()[currentStep]
					)}
					<br />
					{strings.formatString(
						strings.expectedAnswer,
						<b>{getPhonemeDeletion().getAnswers()[currentStep]}</b>
					)}
				</p>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerCorrect();
					}}
				>
					{strings.hotkeyButtonCorrect}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerWrong();
					}}
				>
					{strings.hotkeyButtonWrong}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerUnanswered();
					}}
				>
					{strings.hotkeyButtonUnanswered}
				</button>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.pd_testname
					)}
				</p>
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
}

export default PhonemeDeletionTestAdmin;
